exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-500-tsx": () => import("./../../../src/pages/500.tsx" /* webpackChunkName: "component---src-pages-500-tsx" */),
  "component---src-pages-[slug]-p-tsx": () => import("./../../../src/pages/[slug]/p.tsx" /* webpackChunkName: "component---src-pages-[slug]-p-tsx" */),
  "component---src-pages-account-tsx": () => import("./../../../src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-checkout-tsx": () => import("./../../../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-logout-tsx": () => import("./../../../src/pages/logout.tsx" /* webpackChunkName: "component---src-pages-logout-tsx" */),
  "component---src-pages-lp-tsx": () => import("./../../../src/pages/lp.tsx" /* webpackChunkName: "component---src-pages-lp-tsx" */),
  "component---src-pages-s-tsx": () => import("./../../../src/pages/s.tsx" /* webpackChunkName: "component---src-pages-s-tsx" */),
  "component---src-pages-slug-tsx": () => import("./../../../src/pages/[...slug].tsx" /* webpackChunkName: "component---src-pages-slug-tsx" */),
  "component---src-pages-volantino-natale-tsx": () => import("./../../../src/pages/volantino-natale.tsx" /* webpackChunkName: "component---src-pages-volantino-natale-tsx" */),
  "component---src-pages-volantino-prezzi-ribassati-tsx": () => import("./../../../src/pages/volantino-prezzi-ribassati.tsx" /* webpackChunkName: "component---src-pages-volantino-prezzi-ribassati-tsx" */),
  "component---src-templates-booxie-mobile-page-tsx": () => import("./../../../src/templates/booxieMobilePage.tsx" /* webpackChunkName: "component---src-templates-booxie-mobile-page-tsx" */),
  "component---src-templates-brand-tsx": () => import("./../../../src/templates/brand.tsx" /* webpackChunkName: "component---src-templates-brand-tsx" */),
  "component---src-templates-fullscreen-tsx": () => import("./../../../src/templates/fullscreen.tsx" /* webpackChunkName: "component---src-templates-fullscreen-tsx" */),
  "component---src-templates-lp-for-mobile-app-tsx": () => import("./../../../src/templates/lpForMobileApp.tsx" /* webpackChunkName: "component---src-templates-lp-for-mobile-app-tsx" */),
  "component---src-templates-no-layout-tsx": () => import("./../../../src/templates/noLayout.tsx" /* webpackChunkName: "component---src-templates-no-layout-tsx" */),
  "component---src-templates-plp-tsx": () => import("./../../../src/templates/plp.tsx" /* webpackChunkName: "component---src-templates-plp-tsx" */)
}

