const ACCOUNT = 'arcaplanet'
const WORKSPACE = 'master'
const API_ACCOUNT = 'secure'
const GOOGLE_API_KEY = 'AIzaSyBcZZDWB0H_NdgkF4oh5UZLkWoux-enwCI'
const YEXT_API_KEY = 'bbf104338576c3be2c6e5c117ac86e61'
const DELIVERY_RANGE_FROM = 3
const DELIVERY_RANGE_TO = 6
// The variable above is the range counting weekend days, while to one below is the range counting only working days
const DELIVERY_RANGE_TO_WORKING_DAYS = 4
const FAST_PICKING = 2
// We set this var to 24 during black friday period and to null during the rest of the year
const BLACK_FRIDAY_PICKING = null
const LP_FOR_APP = '/fromApp'

module.exports = {
  account: ACCOUNT,
  apiAccount: API_ACCOUNT,
  workspace: WORKSPACE,
  // Ecommerce Platform
  platform: 'vtex',
  invalidDateNewLogic: true,

  // Platform specific configs for API
  api: {
    storeId: ACCOUNT,
    apiAccount: API_ACCOUNT,
    environment: 'vtexcommercestable',
    hideUnavailableItems: false,
    workspace: WORKSPACE,
    googleApiKey: GOOGLE_API_KEY,
    yextApiKey: YEXT_API_KEY,
  },

  // Default channel
  session: {
    currency: {
      code: 'EUR',
      symbol: '€',
    },
    locale: 'it-IT',
    channel: '{"salesChannel":"1","regionId":""}',
    country: 'ITA',
    postalCode: null,
    person: null,
  },

  // Default cart
  cart: {
    id: '',
    items: [],
    messages: [],
    shouldSplitItem: false,
  },

  // Production URLs

  storeUrl: 'https://www.arcaplanet.it',
  secureSubdomain: 'https://secure.arcaplanet.it',
  checkoutUrl: 'https://secure.arcaplanet.it/checkout',
  loginUrl: 'https://secure.arcaplanet.it/login',
  accountUrl: 'https://secure.arcaplanet.it/account',

  delivery: {
    from: DELIVERY_RANGE_FROM,
    to: DELIVERY_RANGE_TO,
    toWorkingDays: DELIVERY_RANGE_TO_WORKING_DAYS,
    defaultPostalCode: '16043',
  },

  fast_picking: FAST_PICKING,
  black_friday_picking: BLACK_FRIDAY_PICKING,
  lp_for_app: LP_FOR_APP,

  lighthouse: {
    server: process.env.BASE_SITE_URL || 'http://localhost:9000',
    pages: {
      home: '/',
      pdp: '/ultima-gatto-sterilized-salmone-400g-8515/p',
      collection: '/cane',
    },
  },

  // E2E CI
  cypress: {
    username: 'testcypress@yopmail.com',
    password: 'cypresstest1!',
    pages: {
      home: '/',
      pdp: '/monge-all-breeds-cane-adult-salmone-riso-12kg-8774/p',
      pdp_variants: '/virtus-dog-puppy-native-8506/p',
      pdp_minimum_order:
        '/almo-nature-holistic-gatto-hairball-multipack-6x70g-6033/p',
      pdp_private_label: '/kit-risparmio-2x10-kg-hi-duk-dog-17000/p',
      pdp_progressive_discount: '/hi-fish-dog-adult-lattina-300g-5204/p',
      pdp_fast_picking_product:
        '/virtus-snack-cat-nutrition-selection--maiale-latte-e-mirtilli-16157/p',
      collection: '/cane/cibo-secco',
      collection_filtered:
        '/cane/cibo-secco?category-1=prodotti-per-cani&category-2=cibo-per-cani&facets=category-1%2Ccategory-2&sort=score_desc&page=0',
      collection_filtered_first_not_shippable_in_store:
        '/s/?q=rio&sort=score_desc&page=0',
      search: '/s?q=cane',
    },
  },

  experimental: {
    nodeVersion: 18,
  },
}
